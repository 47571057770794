import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { close } from '../components/Images';
import { ModalContext } from '../contexts/ModalContext';

/**
 * Primary UI component for user interaction
 */
export const NiftyModal = ({ showModal, title, modalVisibilityChanged, children, ...props }) => {
  const { hideAllModals } = React.useContext(ModalContext);

  const hideModals = () => {
    // setHidden(true);
    if(hideAllModals instanceof Function) hideAllModals();
    if(modalVisibilityChanged instanceof Function) modalVisibilityChanged(false);
  }

  return (
    showModal && (
      <div
        {...props}
        className="relative"
        style={{ zIndex: 100 }}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>
        <div className="fixed inset-0 overflow-y-auto" style={{ zIndex: 100 }}>
          <div
            id="modal-bg"
            className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0"
          >
            <div className="relative p-6 overflow-hidden transition-all transform bg-white border shadow-xl rounded-2xl border-nifty-black sm:my-8 sm:w-full sm:max-w-xl">
              <div className="flex items-center">
                <button
                  onClick={hideModals}
                  id="login-close-btn"
                >
                  <img src={close} className="absolute top-6 right-6" />
                </button>
                <p className="w-2/3 text-left text-nifty-black font-roboto-condensed-black text-mobile-small sm:text-small">
                  {title}
                </p>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

NiftyModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
};

NiftyModal.defaultProps = {
  children: null,
  title: 'Modal Title',
  showModal: true,
};
