import React, { createContext, useReducer } from 'react';

const initialState = {
  offerModalVisible: false,
};

const ModalContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_OFFER_MODAL_VISIBLE':
      return { ...state, offerModalVisible: action.payload };
    case 'HIDE_ALL_MODALS':
      return { ...state, offerModalVisible: false };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const ModalProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setOfferModalVisible = (visible) => {
    dispatch({ type: 'SET_OFFER_MODAL_VISIBLE', payload: visible });
  }

  const hideAllModals = () => {
    dispatch({ type: 'HIDE_ALL_MODALS' });
  }

  const value = { offerModalVisible: state.offerModalVisible, setOfferModalVisible, hideAllModals };

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };